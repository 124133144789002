.wrapper {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.item {
  flex-grow: 1;
  flex-shrink: 1;
  height: 27vh;
  width: calc(100% / 6);
  padding: 2px;
  position: relative;
}

.image {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.videoIcon {
  position: absolute;
  top: 8px;
  right: 8px;
  color: black;
  font-size: 20px;
}
