.tableWrapper {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}

.threeImageList {
  display: flex;
  flex-wrap: no-wrap;
}

.selectionButton {
  width: 350px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  padding: 4px 24px;
  font-weight: normal;
  font-size: 14px;
}

.good {
  background: #fafdf7;
  border: 2px solid #eaf5df;
  margin-right: 12px;
}

.bad {
  background: #fff6f9;
  border: 1px solid #fedbe4;
}

.good .button {
  background: #abd7a9;
  border-color: #6bba6b;
  color: green;
}

.good .button:hover {
  background: rgb(116, 208, 116);
}

.square {
  cursor: pointer;
  width: 88px;
  height: 72px;
  background: #e7eae8;
  border-radius: 3px;
  border: 1px solid #b4b9be;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}
.square:hover {
  font-weight: 700;
}

.wordText {
  font-size: 14.5px;
  display: flex;
  text-align: center;
  white-space: break-spaces;
  align-items: center;
}

.mb8 {
  margin-bottom: 8px;
}
