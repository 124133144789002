.formContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  padding: 24px;
  background: #fff;
}

.form {
  padding: 24px;
  font-size: 16px;
  width: 100%;
  max-width: 1000px;
}

.formItem {
  margin-bottom: 0;
}

.error {
  color: red;
  margin-top: 10px;
}
