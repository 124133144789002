.formContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  padding: 24px;
  padding-top: 0;
  font-size: 16px;
  width: 100%;
  max-width: 1200px;
}

.error {
  color: red;
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1em;
}

.container {
  margin-top: 12px;
  padding: 24px;
  background: #fff;
}

.steps {
  margin-bottom: 30px;
}

.fullWidth {
  width: 100%;
}

.formItem {
  margin-bottom: 0;
}

.visible {
  opacity: 1;
  transition: opacity 0.2s ease-in;
}

.hidden {
  opacity: 0;
  overflow: hidden;
  max-height: 0;
  transition: opacity 0.2s ease-in;
}

.buttonsContainer {
  margin-top: 24px;
}

.miniFormContainer {
  padding: 24px;
  padding-bottom: 12px;
  padding-left: 0;
  margin-bottom: 24px;
  width: 95%;
  margin-left: auto;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

@media screen and (max-width: 600px) {
  .miniFormContainer {
    width: 100%;
  }
}

.lightBold {
  font-weight: 500;
}

.pointerCursor {
  cursor: pointer;
}

.tagsContainer {
  margin-top: 12px;
}

.inputError {
  margin-top: 6px;
  color: red;
  font-size: 0.9em;
}

.displayNone {
  display: none;
}

.templatesHeader {
  margin-bottom: 24px;
}

.newTag {
  margin-left: 6px;
}

.htmlTextEditor {
  width: 50%;
}

.emailTemplateContainer {
  display: flex;
}

.rightAligned {
  display: flex;
  justify-content: flex-end;
}

.createCampaignButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  margin-right: 12%;
}

.mt24 {
  margin-top: 24px;
}

.campaignDetailsContainer {
  padding: 15px;
  border: 1px solid lightgray;
  max-height: 60vh;
  overflow-y: scroll;
  border-radius: 2px 2px 0 0;
  margin-left: 12%;
  margin-right: 12%;
  background: #ececec;
}

.photoExamplesContainer {
  display: flex;
  width: fit-content;
}
