.userMeta {
  width: 40%;
  margin-right: 24px;
}

.checkIcon {
  font-size: 32px;
  margin-right: 8px;
  margin-bottom: 8px;
}
