.formContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  padding: 24px;
  background: #fff;
}

.form {
  padding: 24px;
  font-size: 16px;
  width: 100%;
  max-width: 1000px;
}

.fullWidth {
  width: 100%;
}

.error {
  color: red;
  margin-top: 10px;
}

.success {
  color: green;
  margin-top: 10px;
}

.warning {
  color: darkorange;
  margin-top: 10px;
}

.formItem {
  margin-bottom: 0;
}

.link {
  display: inline-block;
  margin-top: 10px;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.eventsContainer {
  margin-top: -8px;
  margin-bottom: -20px;
}

.divider {
  margin-top: 12px;
}
