.formContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  padding-top: 40px;
}

.form {
  padding-top: 0;
  font-size: 16px;
  width: 100%;
  max-width: 1000px;
}

.copyIcon {
  color: black;
}

.error {
  color: red;
  margin-bottom: 24px;
  margin-left: 24px;
  margin-right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1em;
}

.checkIcon {
  font-size: 24px;
  margin-right: 8px;
  margin-bottom: 8px;
}
