.formLabel {
  font-weight: 500;
}

.formColumn {
}

.textFormColumn {
  margin-bottom: 24px;
}

.formItem {
}

.labelLink {
  margin-left: 12px;
}
