.fullWidth {
  width: 100%;
}

.error {
  color: red;
  margin-top: 10px;
}

.formItem {
  margin-bottom: 0;
}
